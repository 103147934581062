


































































































































































































































































































import { SfButton, SfCheckbox, SfHeading, SfInput, SfSelect, } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { digits, min, required } from 'vee-validate/dist/rules';
import { computed, defineComponent, onMounted, ref, useContext, useRouter, } from '@nuxtjs/composition-api';
import userBillingGetters from '~/modules/customer/getters/userBillingGetters';
import addressGetter from '~/modules/customer/getters/addressGetter';
import { useCountrySearch } from '~/composables';

import useShipping from '~/modules/checkout/composables/useShipping';
import useBilling from '~/modules/checkout/composables/useBilling';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUserAddress } from '~/modules/customer/composables/useUserAddress';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import {
  addressFromApiToForm,
  CheckoutAddressForm,
  findUserAddressIdenticalToSavedCartAddress,
  formatAddressReturnToData,
  getInitialCheckoutAddressForm,
} from '~/helpers/checkout/address';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';

import type { Country, Customer, CustomerAddress, ShippingCartAddress, } from '~/modules/GraphQL/types';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('digits', {
  ...digits,
  message: 'Please provide a valid phone number',
});

export default defineComponent({
  name: 'BillingStep',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    UserBillingAddresses: () => import('~/modules/checkout/components/UserBillingAddresses.vue'),
    UserAddressDetails,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const shippingDetails = ref<ShippingCartAddress | null>(null);
    const userBilling = ref<Customer | null>(null);

    const {
      save, load: loadBilling, loading,
    } = useBilling();
    const {
      load: loadUserBilling,
      setDefaultAddress,
    } = useUserAddress();
    const {
      load: loadShipping,
    } = useShipping();
    const {
      load: loadCountries,
      search: searchCountry,
    } = useCountrySearch();

    const countries = ref<Country[]>([]);
    const country = ref<Country | null>(null);

    const shippingDetailsCountryName = computed(() => countries
      .value
      .find((countryItem) => countryItem.id === shippingDetails.value?.country.code)?.full_name_locale ?? '');

    const { isAuthenticated } = useUser();
    let oldBilling: CheckoutAddressForm | null = null;
    const sameAsShipping = ref(false);
    const billingDetails = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());

    const currentAddressId = ref<number | null>(null);
    const setAsDefault = ref(false);
    const isFormSubmitted = ref(false);
    const isAddNewAddressFormVisible = ref(true);

    const isBillingDetailsStepCompleted = ref(false);
    const addresses = computed(() => (userBilling.value ? userBillingGetters.getAddresses(userBilling.value) : []));

    const canMoveForward = computed(() => !loading.value && billingDetails.value && Object.keys(
      billingDetails.value,
    ).length > 0);

    const hasSavedBillingAddress = computed(() => {
      if (!isAuthenticated.value || !userBilling.value) {
        return false;
      }
      return addresses.value.length > 0;
    });

    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const handleAddressSubmit = (reset: () => void) => async () => {
      const addressId = currentAddressId.value;
      const billingDetailsData = {
        billingDetails: {
          ...billingDetails.value,
          customerAddressId: addressId === null ? null : String(addressId),
          sameAsShipping: sameAsShipping.value,
          save_in_address_book: false,
        },
      };
      await save(billingDetailsData);
      if (addressId !== null && setAsDefault.value) {
        const [chosenAddress] = userBillingGetters.getAddresses(
          userBilling.value,
          { id: addressId },
        );
        chosenAddress.default_billing = setAsDefault.value;
        if (chosenAddress) {
          await setDefaultAddress({ address: chosenAddress });
          userBilling.value = await loadUserBilling(true);
        }
      }
      reset();
      await mergeItem('checkout', { billing: billingDetailsData });
      await router.push(app.localeRoute({ name: 'payment' }));
      isBillingDetailsStepCompleted.value = true;
    };

    const handleCheckSameAddress = async (value: boolean) => {
      sameAsShipping.value = value;
      if (value) {
        shippingDetails.value = await loadShipping();
        country.value = await searchCountry({ id: (shippingDetails.value).country.code });
        oldBilling = { ...billingDetails.value };
        billingDetails.value = {
          ...formatAddressReturnToData(shippingDetails.value),
        };
        currentAddressId.value = null;
        setAsDefault.value = false;
        if (billingDetails.value.country_code) {
          country.value = await searchCountry({ id: billingDetails?.value.country_code });
        }
        return;
      }
      billingDetails.value = oldBilling;
      if (billingDetails.value.country_code) {
        country.value = await searchCountry({ id: billingDetails?.value.country_code });
      }
    };

    const handleAddNewAddressBtnClick = () => {
      currentAddressId.value = null;
      billingDetails.value = getInitialCheckoutAddressForm();
      isAddNewAddressFormVisible.value = true;
      isBillingDetailsStepCompleted.value = false;
    };

    const handleSetCurrentAddress = async (customerAddress: CustomerAddress) => {
      const id = customerAddress?.id;
      currentAddressId.value = id;
      if (id) {
        isAddNewAddressFormVisible.value = false;
      }
      billingDetails.value = addressFromApiToForm(customerAddress);
      country.value = customerAddress.country_code ? await searchCountry({ id: customerAddress.country_code }) : null;
      isBillingDetailsStepCompleted.value = false;
    };

    const changeBillingDetails = (field: keyof CheckoutAddressForm, value: string) => {
      billingDetails.value[field] = value;
      currentAddressId.value = null;
      isBillingDetailsStepCompleted.value = false;
    };

    const changeCountry = async (id: string) => {
      changeBillingDetails('country_code', id);
      const newCountry = await searchCountry({ id });
      billingDetails.value.region = '';
      country.value = newCountry;
    };

    onMounted(async () => {
      const validStep = await isPreviousStepValid('user-account');
      if (!validStep) {
        await router.push(app.localeRoute({ name: 'user-account' }));
      }
      const [loadedBillingInfoBoundToCart, loadedUserBilling, loadedCountries] = await Promise.all([
        loadBilling(),
        loadUserBilling(),
        loadCountries(),
      ]);
      const [defaultAddress = null] = userBillingGetters.getAddresses(loadedUserBilling, { default_billing: true });
      const wasBillingAddressAlreadySetOnCart = Boolean(loadedBillingInfoBoundToCart);

      // keep in mind default billing address is set on a customer's cart during cart creation
      if (wasBillingAddressAlreadySetOnCart) {
        const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
          loadedUserBilling?.addresses,
          loadedBillingInfoBoundToCart,
        );

        handleSetCurrentAddress({ ...loadedBillingInfoBoundToCart, id: userAddressIdenticalToSavedCartAddress?.id });
      } else if (defaultAddress) {
        handleSetCurrentAddress(defaultAddress);
      }
      if (billingDetails.value?.country_code) {
        country.value = await searchCountry({ id: billingDetails.value.country_code });
      }
      userBilling.value = loadedUserBilling;
      countries.value = loadedCountries;
    });

    return {
      isAddNewAddressFormVisible,
      canMoveForward,
      changeCountry,
      changeBillingDetails,
      countriesList,
      countries,
      country,
      currentAddressId,
      handleAddNewAddressBtnClick,
      handleAddressSubmit,
      handleSetCurrentAddress,
      handleCheckSameAddress,
      hasSavedBillingAddress,
      isAuthenticated,
      isFormSubmitted,
      isBillingDetailsStepCompleted,
      loading,
      regionInformation,
      searchCountry,
      setAsDefault,
      billingDetails,
      sameAsShipping,
      shippingDetailsCountryName,
      addresses,
    };
  },
});
